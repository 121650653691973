.AuthView {
  text-align: center;
  margin: 10% 30%;
  min-width: 450px;
  height:400px;
  background-color:aliceblue;
}

.AuthTitleHeader {
  font-size: 1.625rem;
  text-align: center;
  /* font-weight: bold;
  font-size: larger;
  padding-top: 15px;
  padding-bottom: 15px; */
}

.AuthTitleComment {
  text-align: left;
  border-width: 5px;
  border-color: black;
  margin: 5px 3px;
  max-width: 100%;
  /* font-size: small; */
}

.AuthCommentSubMessage {
  text-align: left;
  border-width: 5px;
  border-color: black;
  margin: 5px 3px;
  max-width: 100%;
  font-size: small;
    /* font-size: x-small; */
}


.TitleHeaderAppName {
  font-size: 1.3rem;
}

.TitleHeaderAppNameForIOs {
  font-size: 1.1rem !important;
}

.ListHeaderTitleForIOs {
  font-size: 1.3rem !important;
}

.FileDownloadListView {
  margin-left: 20px;
}

.FileDownloadListTitleHeader p {
  display: inline-block;
}

.FileDownloadListTitle {
  font-weight: bold;
  font-size: xx-large;
  background-color: aliceblue;
  margin-right: 20px;
  padding:5px 25px;
}

#processing {
  display: block;
  opacity: 0.40;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgb(216, 221, 224);
  /* background: transparent; */
  z-index: 1;
}


/* 
.TitleHeader {
  flex-direction:row;
  justify-content:space-between;
} */

.FileDownloadListLogoutLink {
  /* text-align: right; */
  display: block;
  margin-right: 5px;
  /* margin-left: auto; */
  width: 10%;
}

.FileNameCol {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
}

.fileDownloadIcon {
  width: auto !important;
  cursor: pointer;
}

.logoutIcon {
  cursor: pointer;
}


#FileListTitleHeader {
  background-color: #F5F4F3 !important;
  border-bottom: 2px solid #c9c8c6 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.FileListDataRow {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0  !important;
}

@media (hover: hover) {
  .FileListDataRow:hover {
    background-color: #F4F4F4;
  }
}

.PasswordReveal {
  left: auto;
  position: absolute;
  right: 10px;
  top: 20%;
}

::-ms-reveal {
  display: none;
}

input::-webkit-credentials-auto-fill-button {
  margin: 0;
  width: 0;
  background-color: transparent;
}


@media (min-width: 768px) {
  .FileSizeCol {
    text-align: right;
  }
  .FileListPanelStyle {
    width: 90%;
    margin-top:5px;
    /* margin-top:52px; */
  }
  .FileListDataRow {
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

/* @media (pointer: coarse) or (pointer: none){ */
@media (hover: none) {
/* @media (max-width: 767px) { */
  .tooltip {
    display:none !important;
  }
}

/* @media (max-width: 525px) {
  .FileListPanelStyle {
    margin-top:72px !important;
  }
} */

@media (max-width: 550px) {
  #signinInfo {
    width: 240px !important;
    margin-right: 0  !important;
    margin-left: auto !important;
  }
  .FileListPanelStyle {
    margin-top:5px !important;
    /* margin-top:72px !important; */
  }
  #mail.form-control  {
    font-size: 16px !important;
  }
  #passcode.form-control  {
    font-size: 16px !important;
  }
  #bounceDelheaderTitle {
    margin-bottom: 0.1rem !important; 
  }
  #bounceDelLogo {
    margin-bottom: 0.1rem !important;
  }
  #bounceDelForm {
    margin-top: 10px !important;
    margin-bottom: 1px !important;
    padding-bottom: 0px !important;
  }
}

.footer_area {
  max-height: 30px !important;
  position: fixed;
  bottom: 0;
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: snow !important;

}

/* @font-face {
  font-family: 'FujitsuInfinityPro';
  src:
    url('fonts/FujitsuInfinityPro-Bold.woff2') format('woff2'),
    url('fonts/FujitsuInfinityPro-BoldItalic.woff2') format('woff2'),
    url('fonts/FujitsuInfinityPro-ExtraBold.woff2') format('woff2'),
    url('fonts/FujitsuInfinityPro-Light.woff2') format('woff2'),
    url('fonts/FujitsuInfinityPro-Regular.woff2') format('woff2'),
    url('fonts/FujitsuInfinityPro-RegularItalic.woff2') format('woff2'),
    url('fonts/FujitsuInfinityPro-Bold.woff') format('woff'),
    url('fonts/FujitsuInfinityPro-BoldItalic.woff') format('woff'),
    url('fonts/FujitsuInfinityPro-ExtraBold.woff') format('woff'),
    url('fonts/FujitsuInfinityPro-Light.woff') format('woff'),
    url('fonts/FujitsuInfinityPro-Regular.woff') format('woff'),
    url('fonts/FujitsuInfinityPro-RegularItalic.woff') format('woff');
} */

/* body {
	font-family: FujitsuInfinityPro !important;
} */

/* #mail {
  text-align: left;
  width: 400px;
  height:40px;
} */

/* #passcode {
  text-align: left;
  width: 400px;
  height:40px;
} */

/* #nextBtn {
  text-align: center;
  width: 400px;
  height:40px;
  background-color:deepskyblue;
} */

/* #confirmBtn {
  text-align: center;
  width: 400px;
  height:40px;
  background-color:deepskyblue;
} */


.errorMessage {
  text-align: left;
  max-width: 100%;
  margin: 2px 0px;
  color:red;
  font-size: large;
  /* overflow-wrap: break-word; */
}

.passcodeSendMessage {
  text-align: center;
  font-size: large;
  margin: 2px 10px;
  max-width: 100%;
  color:red;
}

.messageParent {
  text-align: center;
  max-width: 100%;
  margin:0 0;
}

.fileListGetErrorMessage {
  text-align: middle;
  max-width: 100%;
  margin: 2px 10px;
  color:red;
  font-size: large;
  /* overflow-wrap: break-word; */
}


.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button-hoveronly-underline {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button-hoveronly-underline:hover{
  text-decoration: underline;
}

/* .login-form-margin {
  margin-top: 10%;
} */

body {
  background-color:snow !important;
  -webkit-text-size-adjust: 100%;
}

/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-align-middle th,
.table-align-middle td {
  vertical-align: middle;
}

.card.card-link:hover {
  color: rgba(0, 0, 0, 0.95);
  text-decoration: none;
}

.card.card-link:hover .card-body {
  background-color: rgba(0, 0, 0, 0.08);
}

.card-body {
  transition: background-color 0.15s ease-in-out;
}

.card-min-height > .row > * {
  min-height: 40px;
}

.project-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 1.25rem;
  line-height: 40px;
  text-align: center;
  background-color: #eee;
}

.sr-only {
  margin: -1px;
}

