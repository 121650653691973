@charset "UTF-8";
/*!
 * FUJITSU GUI Next Plus Icons V3.1
 * Copyright 2018 FUJITSU LIMITED
 */
@font-face {
  font-family: 'FGNP Icons';
  src:
    /* url('../fonts/FGNPIcons.woff2?op4ont') format('woff2'),
    url('../fonts/FGNPIcons.woff?op4ont') format('woff'); */
    url('fonts/FGNPIcons.woff2?op4ont') format('woff2'),
    url('fonts/FGNPIcons.woff?op4ont') format('woff');
  font-weight: normal;
  font-style: normal;
}

.fj-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FGNP Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fj-icon-address::before {
  content: "\e921";
}
.fj-icon-airplane::before {
  content: "\e922";
}
.fj-icon-arrow1-down::before {
  content: "\e923";
}
.fj-icon-arrow1-left::before {
  content: "\e924";
}
.fj-icon-arrow1-right::before {
  content: "\e925";
}
.fj-icon-arrow1-sort::before {
  content: "\e926";
}
.fj-icon-arrow1-up::before {
  content: "\e927";
}
.fj-icon-arrow2-down::before {
  content: "\e928";
}
.fj-icon-arrow2-left::before {
  content: "\e929";
}
.fj-icon-arrow2-right::before {
  content: "\e92a";
}
.fj-icon-arrow2-sort::before {
  content: "\e92b";
}
.fj-icon-arrow2-up::before {
  content: "\e92c";
}
.fj-icon-arrow3-down::before {
  content: "\e92d";
}
.fj-icon-arrow3-left::before {
  content: "\e92e";
}
.fj-icon-arrow3-right::before {
  content: "\e92f";
}
.fj-icon-arrow3-up::before {
  content: "\e930";
}
.fj-icon-arrow4-down::before {
  content: "\e931";
}
.fj-icon-arrow4-left::before {
  content: "\e932";
}
.fj-icon-arrow4-right::before {
  content: "\e933";
}
.fj-icon-arrow4-up::before {
  content: "\e934";
}
.fj-icon-baby::before {
  content: "\e935";
}
.fj-icon-back::before {
  content: "\e936";
}
.fj-icon-ban::before {
  content: "\e937";
}
.fj-icon-bandage::before {
  content: "\e938";
}
.fj-icon-battery-empty::before {
  content: "\e939";
}
.fj-icon-battery-full::before {
  content: "\e93a";
}
.fj-icon-battery-half::before {
  content: "\e93b";
}
.fj-icon-battery-low::before {
  content: "\e93c";
}
.fj-icon-battery-three-fourths::before {
  content: "\e93d";
}
.fj-icon-bed::before {
  content: "\e93e";
}
.fj-icon-bell::before {
  content: "\e93f";
}
.fj-icon-bell-off::before {
  content: "\e940";
}
.fj-icon-bell-outline::before {
  content: "\e941";
}
.fj-icon-bicycle::before {
  content: "\e942";
}
.fj-icon-binoculars::before {
  content: "\e943";
}
.fj-icon-bomb::before {
  content: "\e944";
}
.fj-icon-bookmark::before {
  content: "\e945";
}
.fj-icon-briefcase::before {
  content: "\e946";
}
.fj-icon-bug::before {
  content: "\e947";
}
.fj-icon-building::before {
  content: "\e948";
}
.fj-icon-bus::before {
  content: "\e949";
}
.fj-icon-business-user::before {
  content: "\e94a";
}
.fj-icon-business-user-circle::before {
  content: "\e94b";
}
.fj-icon-calculator::before {
  content: "\e94c";
}
.fj-icon-calendar-daily::before {
  content: "\e94d";
}
.fj-icon-calendar-monthly::before {
  content: "\e94e";
}
.fj-icon-camera::before {
  content: "\e94f";
}
.fj-icon-car::before {
  content: "\e950";
}
.fj-icon-chart-bar::before {
  content: "\e951";
}
.fj-icon-chart-bar-outline::before {
  content: "\e952";
}
.fj-icon-chart-flow::before {
  content: "\e953";
}
.fj-icon-chart-gantt::before {
  content: "\e954";
}
.fj-icon-chart-line::before {
  content: "\e955";
}
.fj-icon-chart-pie::before {
  content: "\e956";
}
.fj-icon-chart-with-window::before {
  content: "\e957";
}
.fj-icon-chassis::before {
  content: "\e958";
}
.fj-icon-check::before {
  content: "\e959";
}
.fj-icon-circle::before {
  content: "\e95a";
}
.fj-icon-circle-outline::before {
  content: "\e95b";
}
.fj-icon-clip::before {
  content: "\e95c";
}
.fj-icon-clock::before {
  content: "\e95d";
}
.fj-icon-clock-outline::before {
  content: "\e95e";
}
.fj-icon-clone::before {
  content: "\e95f";
}
.fj-icon-clothes::before {
  content: "\e960";
}
.fj-icon-cloud::before {
  content: "\e961";
}
.fj-icon-cloud-download-outline::before {
  content: "\e962";
}
.fj-icon-cloud-outline::before {
  content: "\e963";
}
.fj-icon-cloud-upload-outline::before {
  content: "\e964";
}
.fj-icon-code::before {
  content: "\e965";
}
.fj-icon-coffee::before {
  content: "\e966";
}
.fj-icon-color-palette::before {
  content: "\e967";
}
.fj-icon-comment-left::before {
  content: "\e968";
}
.fj-icon-comment-left-outline::before {
  content: "\e969";
}
.fj-icon-comment-right::before {
  content: "\e96a";
}
.fj-icon-comment-right-outline::before {
  content: "\e96b";
}
.fj-icon-comments::before {
  content: "\e96c";
}
.fj-icon-compare-arrows::before {
  content: "\e96d";
}
.fj-icon-compass::before {
  content: "\e96e";
}
.fj-icon-cone::before {
  content: "\e96f";
}
.fj-icon-connect::before {
  content: "\e970";
}
.fj-icon-copy::before {
  content: "\e971";
}
.fj-icon-cpu::before {
  content: "\e972";
}
.fj-icon-credit-card::before {
  content: "\e973";
}
.fj-icon-credit-card-outline::before {
  content: "\e974";
}
.fj-icon-cube::before {
  content: "\e975";
}
.fj-icon-cube-alt::before {
  content: "\e976";
}
.fj-icon-cut::before {
  content: "\e977";
}
.fj-icon-database::before {
  content: "\e978";
}
.fj-icon-database-outline::before {
  content: "\e979";
}
.fj-icon-database-rdb::before {
  content: "\e97a";
}
.fj-icon-delete::before {
  content: "\e97b";
}
.fj-icon-delete-column::before {
  content: "\e97c";
}
.fj-icon-delete-row::before {
  content: "\e97d";
}
.fj-icon-diamond::before {
  content: "\e97e";
}
.fj-icon-diamond-outline::before {
  content: "\e97f";
}
.fj-icon-disc::before {
  content: "\e980";
}
.fj-icon-dislike::before {
  content: "\e981";
}
.fj-icon-doctor::before {
  content: "\e982";
}
.fj-icon-dog::before {
  content: "\e983";
}
.fj-icon-download::before {
  content: "\e984";
}
.fj-icon-draw::before {
  content: "\e985";
}
.fj-icon-earth::before {
  content: "\e986";
}
.fj-icon-edit::before {
  content: "\e987";
}
.fj-icon-education::before {
  content: "\e988";
}
.fj-icon-elderly::before {
  content: "\e989";
}
.fj-icon-electric-car-charge::before {
  content: "\e98a";
}
.fj-icon-electric-car-discharge::before {
  content: "\e98b";
}
.fj-icon-ellipsis::before {
  content: "\e98c";
}
.fj-icon-ellipsis-vertical::before {
  content: "\e98d";
}
.fj-icon-envelope::before {
  content: "\e98e";
}
.fj-icon-erase::before {
  content: "\e98f";
}
.fj-icon-eye::before {
  content: "\e990";
}
.fj-icon-face-frown::before {
  content: "\e991";
}
.fj-icon-face-frown-outline::before {
  content: "\e992";
}
.fj-icon-face-smile::before {
  content: "\e993";
}
.fj-icon-face-smile-outline::before {
  content: "\e994";
}
.fj-icon-fan::before {
  content: "\e995";
}
.fj-icon-fan-pc::before {
  content: "\e996";
}
.fj-icon-fast-forward::before {
  content: "\e997";
}
.fj-icon-favorite::before {
  content: "\e998";
}
.fj-icon-favorite-outline::before {
  content: "\e999";
}
.fj-icon-female::before {
  content: "\e99a";
}
.fj-icon-figures::before {
  content: "\e99b";
}
.fj-icon-file::before {
  content: "\e99c";
}
.fj-icon-file-binary::before {
  content: "\e99d";
}
.fj-icon-file-binary-outline::before {
  content: "\e99e";
}
.fj-icon-file-excel::before {
  content: "\e99f";
}
.fj-icon-file-html::before {
  content: "\e9a0";
}
.fj-icon-file-outline::before {
  content: "\e9a1";
}
.fj-icon-file-pdf::before {
  content: "\e9a2";
}
.fj-icon-file-word::before {
  content: "\e9a3";
}
.fj-icon-filter::before {
  content: "\e9a4";
}
.fj-icon-finger::before {
  content: "\e9a5";
}
.fj-icon-fingerprint::before {
  content: "\e9a6";
}
.fj-icon-fire::before {
  content: "\e9a7";
}
.fj-icon-firewall::before {
  content: "\e9a8";
}
.fj-icon-flag::before {
  content: "\e9a9";
}
.fj-icon-folder::before {
  content: "\e9aa";
}
.fj-icon-folder-open::before {
  content: "\e9ab";
}
.fj-icon-folder-outline::before {
  content: "\e9ac";
}
.fj-icon-folder-vertical-with-file::before {
  content: "\e9ad";
}
.fj-icon-folder-zipped::before {
  content: "\e9ae";
}
.fj-icon-footprint::before {
  content: "\e9af";
}
.fj-icon-form::before {
  content: "\e9b0";
}
.fj-icon-forward::before {
  content: "\e9b1";
}
.fj-icon-fullscreen::before {
  content: "\e9b2";
}
.fj-icon-fullscreen-alt::before {
  content: "\e9b3";
}
.fj-icon-game::before {
  content: "\e9b4";
}
.fj-icon-gear::before {
  content: "\e9b5";
}
.fj-icon-gift::before {
  content: "\e9b6";
}
.fj-icon-gps::before {
  content: "\e9b7";
}
.fj-icon-grid-4::before {
  content: "\e9b8";
}
.fj-icon-grid-4-outline::before {
  content: "\e9b9";
}
.fj-icon-grid-9::before {
  content: "\e9ba";
}
.fj-icon-grid-9-outline::before {
  content: "\e9bb";
}
.fj-icon-group::before {
  content: "\e9bc";
}
.fj-icon-group-alt::before {
  content: "\e9bd";
}
.fj-icon-hard-disk::before {
  content: "\e9be";
}
.fj-icon-headphones::before {
  content: "\e9bf";
}
.fj-icon-heart::before {
  content: "\e9c0";
}
.fj-icon-heart-outline::before {
  content: "\e9c1";
}
.fj-icon-help::before {
  content: "\e9c2";
}
.fj-icon-hems::before {
  content: "\e9c3";
}
.fj-icon-home::before {
  content: "\e9c4";
}
.fj-icon-hospital::before {
  content: "\e9c5";
}
.fj-icon-id-card::before {
  content: "\e9c6";
}
.fj-icon-idea::before {
  content: "\e9c7";
}
.fj-icon-infraredlight::before {
  content: "\e9c8";
}
.fj-icon-injection::before {
  content: "\e9c9";
}
.fj-icon-insert-column::before {
  content: "\e9ca";
}
.fj-icon-insert-row::before {
  content: "\e9cb";
}
.fj-icon-internet::before {
  content: "\e9cc";
}
.fj-icon-invert-horizontal::before {
  content: "\e9cd";
}
.fj-icon-invert-vertical::before {
  content: "\e9ce";
}
.fj-icon-key::before {
  content: "\e9cf";
}
.fj-icon-keyboard::before {
  content: "\e9d0";
}
.fj-icon-lab::before {
  content: "\e9d1";
}
.fj-icon-laptop::before {
  content: "\e9d2";
}
.fj-icon-layer::before {
  content: "\e9d3";
}
.fj-icon-layout::before {
  content: "\e9d4";
}
.fj-icon-leaf::before {
  content: "\e9d5";
}
.fj-icon-like::before {
  content: "\e9d6";
}
.fj-icon-link::before {
  content: "\e9d7";
}
.fj-icon-list::before {
  content: "\e9d8";
}
.fj-icon-list-alt::before {
  content: "\e9d9";
}
.fj-icon-load-balancer::before {
  content: "\e9da";
}
.fj-icon-load-balancer-outline::before {
  content: "\e9db";
}
.fj-icon-lock::before {
  content: "\e9dc";
}
.fj-icon-log::before {
  content: "\e9dd";
}
.fj-icon-login::before {
  content: "\e9de";
}
.fj-icon-logout::before {
  content: "\e9df";
}
.fj-icon-magnetic-tape::before {
  content: "\e9e0";
}
.fj-icon-mail::before {
  content: "\e9e1";
}
.fj-icon-mail-outline::before {
  content: "\e9e2";
}
.fj-icon-male::before {
  content: "\e9e3";
}
.fj-icon-manual::before {
  content: "\e9e4";
}
.fj-icon-manual-outline::before {
  content: "\e9e5";
}
.fj-icon-map::before {
  content: "\e9e6";
}
.fj-icon-medicine::before {
  content: "\e9e7";
}
.fj-icon-memory::before {
  content: "\e9e8";
}
.fj-icon-menu::before {
  content: "\e9e9";
}
.fj-icon-meter::before {
  content: "\e9ea";
}
.fj-icon-microphone::before {
  content: "\e9eb";
}
.fj-icon-microphone-off::before {
  content: "\e9ec";
}
.fj-icon-minus::before {
  content: "\e9ed";
}
.fj-icon-mobile::before {
  content: "\e9ee";
}
.fj-icon-mobile-horizontal::before {
  content: "\e9ef";
}
.fj-icon-money::before {
  content: "\e9f0";
}
.fj-icon-monitor::before {
  content: "\e9f1";
}
.fj-icon-monitor-outline::before {
  content: "\e9f2";
}
.fj-icon-monitors::before {
  content: "\e9f3";
}
.fj-icon-moon::before {
  content: "\e9f4";
}
.fj-icon-mouse::before {
  content: "\e9f5";
}
.fj-icon-move::before {
  content: "\e9f6";
}
.fj-icon-movie::before {
  content: "\e9f7";
}
.fj-icon-music::before {
  content: "\e9f8";
}
.fj-icon-network::before {
  content: "\e9f9";
}
.fj-icon-news::before {
  content: "\e9fa";
}
.fj-icon-paste::before {
  content: "\e9fb";
}
.fj-icon-pause::before {
  content: "\e9fc";
}
.fj-icon-pen::before {
  content: "\e9fd";
}
.fj-icon-phone::before {
  content: "\e9fe";
}
.fj-icon-picture::before {
  content: "\e9ff";
}
.fj-icon-pin-1::before {
  content: "\ea00";
}
.fj-icon-pin-2::before {
  content: "\ea01";
}
.fj-icon-pin-3::before {
  content: "\ea02";
}
.fj-icon-play::before {
  content: "\ea03";
}
.fj-icon-plug::before {
  content: "\ea04";
}
.fj-icon-plus::before {
  content: "\ea05";
}
.fj-icon-pointer::before {
  content: "\ea06";
}
.fj-icon-pointer-alt::before {
  content: "\ea07";
}
.fj-icon-pointer-alt-outline::before {
  content: "\ea08";
}
.fj-icon-pointer-outline::before {
  content: "\ea09";
}
.fj-icon-port::before {
  content: "\ea0a";
}
.fj-icon-power::before {
  content: "\ea0b";
}
.fj-icon-printer::before {
  content: "\ea0c";
}
.fj-icon-puzzle::before {
  content: "\ea0d";
}
.fj-icon-qrcode::before {
  content: "\ea0e";
}
.fj-icon-rain::before {
  content: "\ea0f";
}
.fj-icon-ranking::before {
  content: "\ea10";
}
.fj-icon-record::before {
  content: "\ea11";
}
.fj-icon-refresh::before {
  content: "\ea12";
}
.fj-icon-remove::before {
  content: "\ea13";
}
.fj-icon-repeat::before {
  content: "\ea14";
}
.fj-icon-report::before {
  content: "\ea15";
}
.fj-icon-report-check::before {
  content: "\ea16";
}
.fj-icon-restaurant::before {
  content: "\ea17";
}
.fj-icon-rewind::before {
  content: "\ea18";
}
.fj-icon-robot::before {
  content: "\ea19";
}
.fj-icon-router::before {
  content: "\ea1a";
}
.fj-icon-router-function::before {
  content: "\ea1b";
}
.fj-icon-router-function-outline::before {
  content: "\ea1c";
}
.fj-icon-save::before {
  content: "\ea1d";
}
.fj-icon-sdcard::before {
  content: "\ea1e";
}
.fj-icon-search::before {
  content: "\ea1f";
}
.fj-icon-security::before {
  content: "\ea20";
}
.fj-icon-server::before {
  content: "\ea21";
}
.fj-icon-server-application::before {
  content: "\ea22";
}
.fj-icon-server-database::before {
  content: "\ea23";
}
.fj-icon-server-file::before {
  content: "\ea24";
}
.fj-icon-servers::before {
  content: "\ea25";
}
.fj-icon-server-vm::before {
  content: "\ea26";
}
.fj-icon-server-web::before {
  content: "\ea27";
}
.fj-icon-setting::before {
  content: "\ea28";
}
.fj-icon-setting-alt::before {
  content: "\ea29";
}
.fj-icon-settings::before {
  content: "\ea2a";
}
.fj-icon-share::before {
  content: "\ea2b";
}
.fj-icon-share-alt::before {
  content: "\ea2c";
}
.fj-icon-share-outline::before {
  content: "\ea2d";
}
.fj-icon-ship::before {
  content: "\ea2e";
}
.fj-icon-shopping-cart::before {
  content: "\ea2f";
}
.fj-icon-snow::before {
  content: "\ea30";
}
.fj-icon-sound-wave::before {
  content: "\ea31";
}
.fj-icon-square::before {
  content: "\ea32";
}
.fj-icon-square-outline::before {
  content: "\ea33";
}
.fj-icon-stamp::before {
  content: "\ea34";
}
.fj-icon-status-important-outline::before {
  content: "\ea35";
}
.fj-icon-status-info-outline::before {
  content: "\ea36";
}
.fj-icon-stop::before {
  content: "\ea37";
}
.fj-icon-storage-hardware::before {
  content: "\ea38";
}
.fj-icon-storages::before {
  content: "\ea39";
}
.fj-icon-structure::before {
  content: "\ea3a";
}
.fj-icon-sun::before {
  content: "\ea3b";
}
.fj-icon-surveillance::before {
  content: "\ea3c";
}
.fj-icon-switch::before {
  content: "\ea3d";
}
.fj-icon-system::before {
  content: "\ea3e";
}
.fj-icon-table::before {
  content: "\ea3f";
}
.fj-icon-tablet::before {
  content: "\ea40";
}
.fj-icon-tablet-horizontal::before {
  content: "\ea41";
}
.fj-icon-tag::before {
  content: "\ea42";
}
.fj-icon-target::before {
  content: "\ea43";
}
.fj-icon-taxi::before {
  content: "\ea44";
}
.fj-icon-temperature::before {
  content: "\ea45";
}
.fj-icon-text-alphabet::before {
  content: "\ea46";
}
.fj-icon-text-hiragana::before {
  content: "\ea47";
}
.fj-icon-text-number::before {
  content: "\ea48";
}
.fj-icon-thunderstorm::before {
  content: "\ea49";
}
.fj-icon-timer::before {
  content: "\ea4a";
}
.fj-icon-to-do::before {
  content: "\ea4b";
}
.fj-icon-tools::before {
  content: "\ea4c";
}
.fj-icon-traffic-light-horizontal::before {
  content: "\ea4d";
}
.fj-icon-traffic-light-vertical::before {
  content: "\ea4e";
}
.fj-icon-train::before {
  content: "\ea4f";
}
.fj-icon-treeview::before {
  content: "\ea50";
}
.fj-icon-triangle::before {
  content: "\ea51";
}
.fj-icon-triangle-outline::before {
  content: "\ea52";
}
.fj-icon-unlock::before {
  content: "\ea53";
}
.fj-icon-upload::before {
  content: "\ea54";
}
.fj-icon-user::before {
  content: "\ea55";
}
.fj-icon-user-circle::before {
  content: "\ea56";
}
.fj-icon-user-circle-outline::before {
  content: "\ea57";
}
.fj-icon-user-outline::before {
  content: "\ea58";
}
.fj-icon-van::before {
  content: "\ea59";
}
.fj-icon-video-camera::before {
  content: "\ea5a";
}
.fj-icon-volume::before {
  content: "\ea5b";
}
.fj-icon-volume-off::before {
  content: "\ea5c";
}
.fj-icon-waiting::before {
  content: "\ea5d";
}
.fj-icon-walking::before {
  content: "\ea5e";
}
.fj-icon-water::before {
  content: "\ea5f";
}
.fj-icon-wheelchair::before {
  content: "\ea60";
}
.fj-icon-wifi::before {
  content: "\ea61";
}
.fj-icon-wind::before {
  content: "\ea62";
}
.fj-icon-window::before {
  content: "\ea63";
}
.fj-icon-windows::before {
  content: "\ea64";
}
.fj-icon-zoom-in::before {
  content: "\ea65";
}
.fj-icon-zoom-out::before {
  content: "\ea66";
}
.fj-icon-figures-outline::before {
  content: "\ea68";
}
.fj-icon-grid-2-columns::before {
  content: "\ea69";
}
.fj-icon-grid-2-columns-outline::before {
  content: "\ea6a";
}
.fj-icon-grid-2-rows::before {
  content: "\ea6b";
}
.fj-icon-grid-2-rows-outline::before {
  content: "\ea6c";
}
.fj-icon-hexagon::before {
  content: "\ea6d";
}
.fj-icon-hexagon-outline::before {
  content: "\ea6e";
}
.fj-icon-honeycomb::before {
  content: "\ea6f";
}
.fj-icon-honeycomb-outline::before {
  content: "\ea70";
}
.fj-icon-medal::before {
  content: "\ea71";
}
.fj-icon-podium::before {
  content: "\ea72";
}
.fj-icon-server-outline::before {
  content: "\ea73";
}
.fj-icon-trophy::before {
  content: "\ea74";
}
.fj-icon-user-with-monitor::before {
  content: "\ea75";
}
.fj-icon-battery-charging::before {
  content: "\ea76";
}
.fj-icon-storages-outline::before {
  content: "\ea77";
}
.fj-icon-servers-outline::before {
  content: "\ea78";
}
.fj-icon-file-excel-color-1::before {
  content: "\e900";
}
.fj-icon-file-excel-color-2::before {
  content: "\e901";
  color: #008458;
}
.fj-icon-file-pdf-color-1::before {
  content: "\e902";
}
.fj-icon-file-pdf-color-2::before {
  content: "\e903";
  color: #c90c1f;
}
.fj-icon-file-word-color-1::before {
  content: "\e904";
}
.fj-icon-file-word-color-2::before {
  content: "\e905";
  color: #024c9e;
}
.fj-icon-status-error-1::before {
  content: "\e906";
  color: #b22903;
}
.fj-icon-status-error-2::before {
  content: "\e907";
  color: #d4380d;
}
.fj-icon-status-error-3::before {
  content: "\e908";
  color: #fff;
}
.fj-icon-status-important-1::before {
  content: "\e909";
  color: #d4380d;
}
.fj-icon-status-important-2::before {
  content: "\e90a";
  color: #fff;
}
.fj-icon-status-important-3::before {
  content: "\e90b";
  color: #d4380d;
}
.fj-icon-status-info-1::before {
  content: "\e90c";
  color: #005c94;
}
.fj-icon-status-info-2::before {
  content: "\e90d";
  color: #007cba;
}
.fj-icon-status-info-3::before {
  content: "\e90e";
  color: #fff;
}
.fj-icon-status-normal-1::before {
  content: "\e90f";
  color: #007850;
}
.fj-icon-status-normal-2::before {
  content: "\e910";
  color: #029c70;
}
.fj-icon-status-normal-3::before {
  content: "\e911";
  color: #fff;
}
.fj-icon-status-pause-1::before {
  content: "\e912";
  color: #3c5bb7;
}
.fj-icon-status-pause-2::before {
  content: "\e913";
  color: #476cdc;
}
.fj-icon-status-pause-3::before {
  content: "\e914";
  color: #fff;
}
.fj-icon-status-running-1::before {
  content: "\e915";
  color: #007850;
}
.fj-icon-status-running-2::before {
  content: "\e916";
  color: #029c70;
}
.fj-icon-status-running-3::before {
  content: "\e917";
  color: #fff;
}
.fj-icon-status-stop-1::before {
  content: "\e918";
  color: #3c5bb7;
}
.fj-icon-status-stop-2::before {
  content: "\e919";
  color: #476cdc;
}
.fj-icon-status-stop-3::before {
  content: "\e91a";
  color: #fff;
}
.fj-icon-status-unknown-1::before {
  content: "\e91b";
  color: #5f5f5f;
}
.fj-icon-status-unknown-2::before {
  content: "\e91c";
  color: #7a7a7a;
}
.fj-icon-status-unknown-3::before {
  content: "\e91d";
  color: #fff;
}
.fj-icon-status-warning-1::before {
  content: "\e91e";
  color: #e3af14;
}
.fj-icon-status-warning-2::before {
  content: "\e91f";
  color: #f5ca14;
}
.fj-icon-status-warning-3::before {
  content: "\e920";
}
.fj-icon-cube-fill::before {
  content: "\ea67";
}

/* for V3.0 */
.fj-icon-moniter::before {
  content: "\e9f1";
}
.fj-icon-moniter-outline::before {
  content: "\e9f2";
}
.fj-icon-moniters::before {
  content: "\e9f3";
}
.fj-icon-headphone::before {
  content: "\e9bf";
}

/* ligatures */
.fj-icon-file-excel-color {
  color: #000 !important;
  position: relative;
}
.fj-icon-file-excel-color::before {
  content: '\e901';
  color: #008458;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.fj-icon-file-pdf-color {
  color: #000 !important;
  position: relative;
}
.fj-icon-file-pdf-color::before {
  content: '\e903';
  color: #c90c1f;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.fj-icon-file-word-color {
  color: #000 !important;
  position: relative;
}
.fj-icon-file-word-color::before {
  content: '\e905';
  color: #024c9e;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.fj-icon-status-error {
  color: #b22903 !important;
  position: relative;
}
.fj-icon-status-error::before {
  content: '\e907';
  color: #d4380d;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.fj-icon-status-error::after {
  content: '\e908';
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.fj-icon-status-important {
  color: #d4380d !important;
  position: relative;
}
.fj-icon-status-important::before {
  content: '\e90a';
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.fj-icon-status-important::after {
  content: '\e90b';
  color: #d4380d;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.fj-icon-status-info {
  color: #005c94 !important;
  position: relative;
}
.fj-icon-status-info::before {
  content: '\e90d';
  color: #007cba;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.fj-icon-status-info::after {
  content: '\e90e';
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.fj-icon-status-normal {
  color: #007850 !important;
  position: relative;
}
.fj-icon-status-normal::before {
  content: '\e910';
  color: #029c70;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.fj-icon-status-normal::after {
  content: '\e911';
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.fj-icon-status-pause {
  color: #3c5bb7 !important;
  position: relative;
}
.fj-icon-status-pause::before {
  content: '\e913';
  color: #476cdc;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.fj-icon-status-pause::after {
  content: '\e914';
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.fj-icon-status-running {
  color: #007850 !important;
  position: relative;
}
.fj-icon-status-running::before {
  content: '\e916';
  color: #029c70;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.fj-icon-status-running::after {
  content: '\e917';
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.fj-icon-status-stop {
  color: #3c5bb7 !important;
  position: relative;
}
.fj-icon-status-stop::before {
  content: '\e919';
  color: #476cdc;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.fj-icon-status-stop::after {
  content: '\e91a';
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.fj-icon-status-unknown {
  color: #5f5f5f !important;
  position: relative;
}
.fj-icon-status-unknown::before {
  content: '\e91c';
  color: #7a7a7a;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.fj-icon-status-unknown::after {
  content: '\e91d';
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.fj-icon-status-warning {
  color: #e3af14 !important;
  position: relative;
}
.fj-icon-status-warning::before {
  content: '\e91f';
  color: #f5ca14;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.fj-icon-status-warning::after {
  content: '\e920';
  color: #000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

/* sizes */
.fj-icon-24 {
  font-size: 24px;
}
.fj-icon-32 {
  font-size: 32px;
}
.fj-icon-48 {
  font-size: 48px;
}
.fj-icon-auto {
  font-size: inherit;
}
